html {
    --maxWidth: 1920px;
    --gap: 15rem;
    --theme: #221246;
}

.body {
    position: sticky;
    /* width: 50%; */
    min-height: 100vh;
    display:flex;
    flex-direction: column;
    padding: 0 var(--gap);
}

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background: url("../img/Home Page.png");
}

.search__container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    max-height: 40px;
    margin: 80px 0 20px 0;
    flex: 1;

}

.searchLine {
    border: 0.5px solid var(--theme);
    width: 99%;
    padding: 0;
    margin: 0;
  
}
.search__bar {
    width: 100%;
    padding: 10px;
    background-color: white;
    border-color: var(--theme);
    border-radius: 5px;
}

.search__buttons{
    width: 37px;
    background-color: white;
    border-color: var(--theme);
    border-radius: 5px;
    padding: 0px;
    background-position: center;
    background-size: 30px;
    background-repeat: no-repeat;
    position: relative;
}

.search__grid {
    background-image: url(../img/fluent_apps-list-20-regular.png);
}

.search__list {
    background-image: url(../img/fluent_grid-28-regular.png);
}
.search__filter {
    background-color: var(--theme);
    background-image: url(../img/Vector.png);
}

.books__container{
    position: relative;
    display: flex;
    flex-wrap: wrap; 
    flex-direction: row;

}

.book__container{
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    max-width: 43%;
    background-color: white;
    justify-content:space-between;
    margin: 1% 1%;
    padding: 2%;
    border: 1px solid var(--theme);
    border-radius: 3px;
    flex: 1 11em;
}


.book__content> .book__image {
    height: 100px;
    max-width: 180px;
    object-fit: contain;
    margin-top: 0px;
}

.myBook__left> .book__image {
    width: 90px;
    object-fit: contain;
    margin-top: 0px;
}


.book__container:focus,
.book__container:hover {
    -webkit-box-shadow: 0 0px 10px 3px  rgba(34, 18, 70,0.1);
       -moz-box-shadow: 0 0px 10px 3px  rgba(34, 18, 70,0.1);
            box-shadow: 0 0px 10px 3px  rgba(34, 18, 70,0.1);
}

.book__container > *{
    margin-top: 10px;
}


.book__container > .book__image{
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
}

.book__buttons__profile{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}

.book__buttons__home{
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-self: flex-end;
    width: 100%;


}

.book__buttons__profile > .book__contact{
    width: 70%;
    padding: 10px;
    margin-right:5px; 
    color: white;
    background-color: var(--theme);
    border-color: var(--theme);
    border-radius: 5px;
    cursor: pointer;
    
}

.book__buttons__home > .book__contact{
    width: 100%;
    padding: 10px;
    margin-right:5px; 
    color: white;
    background-color: var(--theme);
    border-color: var(--theme);
    border-radius: 5px;
    cursor: pointer;
    
}

.book__contact:active,
.book__contact:hover{
    font-size: 1.05em;
    border: 3px solid #221246;
    background-color: #472e85;

}
.button___filter{

    display:inline-block;
    width:30%;
    height: 20%;
    padding: 0.5px;
    /* margin-right:5px;  */
    color: white;
    background-color: var(--theme);
    border-color: var(--theme);
    border-radius: 5px;
    /* cursor: pointer; */
}

.button___xfilter{

    display:inline-block;
    /* width:30%;
    height: 20%; */
    margin-left:70%; 
    padding: 0.5px;
    color: white;
    background-color: var(--theme);
    border-color: var(--theme);
    border-radius: 5px;
}


.book__buttons__profile > .book__favorite, .book__delete{
    width: 37px;
    background-color: white;
    border-color: var(--theme);
    border-radius: 5px;
    padding: 0px;
    background-position: center;
    background-size: 30px;
    background-repeat: no-repeat;
    cursor: pointer;

}

.book__buttons__home > .book__favorite {
    width: 37px;
    background-color: white;
    border-color: var(--theme);
    border-radius: 5px;
    padding: 0px;
    background-position: center;
    background-size: 30px;
    background-repeat: no-repeat;
    cursor: pointer;

}

.book__buttons__profile > .book__favorite{
    width: 90px;
}

.book__buttons__profile >  .book__delete {
    width: 90px;
}

/* .book__buttons__home > .book__favorite {
    width: 60px;
} */

.book__favorite, .book__delete:hover,
.book__favorite, .book__delete:active {
    font-size: 1.10em;
    border: 3px solid #221246;
}

.book__report {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 35px;
    height: 35px;
    background-color: transparent;
    border:0;
    padding: 0px;
    background-position: center;
    background-size: 20px;
    background-repeat: no-repeat;
}

.book__report:active,
.book__report:hover {
    background-size: 25px;
}


.reportIcon{
    background-image: url(../img/reportIcon.png);
}

.InActiveFavorite{
    background-image: url(../img/InActiveFavorite.png);
}

.ActiveFavorite{
    background-image: url(../img/ActiveFavorite.png);
}
.left{
    text-align: left;
}

@media (max-width:1160px){
    html {
        --maxWidth: 1920px;
        --gap: 1rem;
    }
    .search__bar {
        width: 100%;
        padding: 10px;
        background-color: white;
        border-color: var(--theme);
        border-radius: 5px;
    }

    .book__container{
        flex: 1 8em;
    }

    .book__report {
        top: 0px;
        right: 0px;
        width: 35px;
        height: 35px;
    }
    
    .book__content> .book__image {
        max-width: 120px;
    }
}

