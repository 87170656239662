.main{
    border-style: solid;
    border-width: thin;
    border-color: black;
    border-radius: 10px;
    background-color:white;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 35%;
    height: 85%;
}

.add__book{
    height: 60%;
}

.log__In{
    height: 70%;

}

.forgot__password{
    height: 50%;

}

.name-box{
   flex: 1;
   flex-direction:row;
   background-color: white;

   border-radius: 50px;
}

.pic{

height: 80px;
display: flex;
justify-content: center;

}
.input__style{
    margin-top: 10px;
    width: 80%;
    padding: 10px;
    background-color: white;
    border-color: var(--theme);
    border-radius: 5px;
    
}
