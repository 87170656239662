/* .container {
    margin-bottom: 5px;
    
} */

.first {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(213, 213, 213);
    width: 70%;
    margin: 200px auto;
    padding: 60px;
    border-radius: 10px;
    gap: 30px;
    
    box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.img-contain {
    background-size: cover;
    /* background-position: center; */
    height: 250px;
    width: 100%;
    /* transition: all 1s ease; */
    transform: scale(1);
    transition: 0.5s ease-in-out;
}
.imagee {
  overflow: hidden;
  border-radius: 20px;
  min-width: 300px;
  /* background-position: center; */


}

.text-area {
    max-width: 800px ;
    overflow: hidden;
    /* width: 600px; */
    text-align: left;
}
.text-area h2{
    color: #472e85;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-family: "Times New Roman", Times, serif;
    font-size: 30px;
    border-left: 5px solid #221246;
    padding-left: 12px;
    border-radius: 6px;
}
.text-area p{
    font-size: 20px;
    color: rgb(27, 29, 31);
    text-transform: capitalize;
    line-height: 1.1;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serifs
}

.text-area p span{
 text-align: center;
 padding-left: 25%;
 font-weight: 800;
 letter-spacing: 1.1px;
 font-style: italic;
 font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.img-contain:hover {
    transform: scale(1.2);
  }

.img-face {
  max-width: 100%;
  height: auto;
    /* width: 80%;
    height: 90vh; */
    background-size: cover;
    background-position: center;
    opacity: 0.7;
    border-radius: 0 0 300px 300px;
    border: 10px solid bisque;
    -webkit-border-radius: 0 0 300px 300px;
    -moz-border-radius: 0 0 300px 300px;
    -ms-border-radius: 0 0 300px 300px;
    -o-border-radius: 0 0 300px 300px;
}
hr {
  border: 1px solid #221246;
  border-radius: 5px;
  width: 80%;
  margin: auto;
  margin-bottom: 5px;
}


@media only screen and (max-width: 600px) {
    .first {
      width: 80%;
      flex-direction: column;
      padding: 30px;
    }
  
    .img-contain {
      width: 300px;
      height: 200px;
    }
  
    .text-area {
      max-width: 100%;
    }
  }