.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);

    display: flex;
    justify-content: center;
    align-items: center;

}

.popup__container{
    position: relative;
    text-align: left;
    top: 40px;
    padding: 32px;
    width: 100%;
    max-width: 700px;
    background-color: white;
    border-radius: 10px;
    z-index: 5;
    display: flex;
    flex-wrap: wrap; 
    flex-direction: column;
    text-align: center;

}

.popup__container .save__button{
    bottom: 22px;
    right: 130px;
}

.popup__container .close__button{
    bottom: 22px;
    right: 32px;
}

.popup__container .save__button, .close__button{
    position: absolute;
    color: #1976D2;
    background-color: white;
    border: solid 0px;
    font-size: 1.2rem;

}

.popup__container .close__button:hover, .save__button:hover{
    text-decoration: underline;
    cursor: pointer;   
}

.popup__container h3{
    margin-bottom: 10px;
    align-items: flex-start;

}

.popup__container h5, textarea, .input__failed{
    border: solid 1px #221246;
    border-radius: 5px;
    margin: 5px auto;
    padding: 10px;
    width: 96%;
    overflow-x: hidden; 

}

.row__inputs{
    display: flex;
    justify-content: space-between;
}

.ten__px__margin__to__right{
    margin-right: 10px;
}



@media (max-width:1160px) {
    .popup__container{
        width: 70vw;
    }

}


 