.margin-top{
    margin-top: 60px;
}

.seee{
    /* display: flex;
    justify-content: space-between; */
    margin-top: 100px;
    float: left;
    
}

.nib-img{
    /* height: 60vh;
    width: 300%;
    margin-left: 10%; 
    margin-top: 50px;
	background-image: url('../img/nib1.JPG');
	background-size: cover;
    border-radius: 20px;
    border: 5px solid #fe8d2d; */
    /* display: flex;
    justify-content: space-between; */
}
.img1{
    height: 60vh;
    width: 100vh; 
    margin-left: 50%;
    background-size: cover;
    border-radius: 20px;
    border: 5px solid #fe8d2d;
    
    margin-top: 50px;
}

.nibras-talk{
    font-size: 50px;
    color: #221246;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    letter-spacing: 10px;
    word-spacing: 25px; 
}

.episode{
    margin-top: 50px;
    padding: 50px;
    text-align: left;
    max-width: 500px;
}

.episode .be{
    border-left: 4px solid #fe8d2d;
    
}

.episode h4{
    font-weight: bolder;
    font-size: 40px;
    color: black;
    /* border-left: 4px solid #221246; */
    word-spacing: 10px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

}

.episode p{
    font-size: 20px;
    margin-top: 40px;
    word-spacing: 5px;
}