* {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
    transition-property: all;
    transition-duration: .6s;
    transition-timing-function: ease;
} 



.header {
    padding: 0;
    margin: 0;
    top: 0;
    background-color: #fff;
    height: 80px;
    padding-bottom: 10px;
    display: flex;
    text-align: center;
    flex-direction: row;
    align-items: center;
    position: sticky;
    z-index: 100;
    justify-content: center;
    /* box-shadow: 0px 2px 4px #221246; */


}
.one-edge-shadow {
    -webkit-box-shadow: 0 8px 6px -6px  #221246;
       -moz-box-shadow: 0 8px 6px -6px  #221246;
            box-shadow: 0 8px 6px -6px  #221246;
}

.header__container {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    width: 70vw;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 var(--gap);
}


.header__logo {
    display: flex;
    width: 85px;
    object-fit: contain;
    margin-top: 0px;
}

.header__logo:hover,
.header__logo:active {
    width: 100px;
}


.header__nav, .header__navBtn {
    width: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    
}

.header__option {
    display: flex;
    margin-left: 5%;
    margin-right: 5%;
}

.link {
    text-decoration: none;
    color: #221246;
    font-size: 1.2rem;
}

.header__buttons {
    display: flex;
    flex-direction: row;
    margin: 0;
}



.header__signUp {
    padding: 10px 25px;
    font-size: 1.05em;
    margin: 20px 0px 20px 5px;
    border: 2px solid #221246;
    border-radius: 8px;
    color: white;
    background-color: #221246;
    cursor: pointer;
    min-width: 50px;
}

.header__signUp:hover,
.header__signUp:active {
    font-size: 1.10em;
    border: 3px solid #221246;
    background-color: #472e85;
}

.btnlink{
    align-items: stretch;
    text-decoration: none;
    color: #221246;
}
/*
.btn {
    letter-spacing: 0;
}

.btn:hover,
.btn:active {
  letter-spacing: 5px;
}

.btn:after,
.btn:before {
  backface-visibility: hidden;
  border: 1px solid rgba(#221246, 0);
  bottom: 0px;
  content: " ";
  display: block;
  margin: 0 auto;
  position: relative;
  transition: all 280ms ease-in-out;
  width: 0;
}

.btn:hover:after,
.btn:hover:before {
  backface-visibility: hidden;
  border-color: #221246;
  transition: width 350ms ease-in-out;
  width: 70%;
}

.btn:hover:before {
  bottom: auto;
  top: 0;
  width: 70%;
}
*/

/*Here new navbar css by aziz*/

.btn{
  position: relative;  
  text-transform: uppercase;
}
.btn::before{
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    bottom: -2px;
    background: #221246;
    transform: scaleX(0);
    transition: transform 0.5s ease-in-out;
    transform-origin: right;
}
.btn:hover::before{
    transform: scaleX(1);
    transform-origin: left;
}

.btn:hover{
    font-weight: bold;
    transition: transform 0.5s ease-in-out; 
}

.icon{
    display:none;
}

.header__login {
    padding: 10px 25px;
    font-size: 1.05em;
    margin: 20px 0px;
    border: 2px solid var(--theme);
    border-radius: 8px;
    color: var(--theme);
    background-color: white;
    cursor: pointer;

}

.header__login{
    background-color: white;
    border-bottom: 1px solid #221246;

}

.header__login:hover,
.header__login:active {
    font-size: 1.10em;
    background-color: white;
    border: 3px solid #221246;
}

@media (max-width:1160px) {

   .menu{
        display:block;
        color: #221246;
        margin-top: 10px;
        text-align: center;
        padding: 10px;
        cursor: pointer;   
    }

   .close {
        display:block;
        color: #fff;
        text-align: center;
        cursor: pointer;
        padding: 30px 150px 10px 0px;
        /* background-color:cyan; */
    }
    
    .header__navBtn {
        position: absolute;
        flex-direction: column;
        background-color: #221246;
        height: 100vh;
        width: 200px;
        top: 0;
        text-align: center;
        z-index: 2;
    }
    .active{
        right: 0px;
    }

    .inActive{
        right: -200px;
    }

    .header__buttons:nth-child(3){
        margin-top: 10%;
        order: 1;
        /* background-color: cyan;  */
    }

    .header__nav:nth-child(2) {
        height: 40%;
        margin-bottom: 120%;
        /* background-color: yellow; */
        text-align: center;
        flex-direction: column;
        order: 2;

    }
    
    .header__option {
        display: inline;
        text-align: center;
        width:90%;
        padding: 15px 0px;
        font-size: 1.10em;
        margin: 2px 0px;
        border-bottom: 1px solid white;
        border-radius: 0px;
        color: white;
        background-color: #221246;
        
       
    }
    
    .link {
        text-decoration: none;
        font-size: 1.2rem;
        color: white;
        letter-spacing: 1px;

    }
    
    .header__container {
        width: 100vw;
        justify-content: space-between;
    }
    
    
    .header__buttons {
        display: flex;
        flex-direction: column;
        width:100%;
        min-width: 50px;
        margin: 0;

    }
    .header__login,  .header__signUp {
        text-align: center;
        padding: 15px 0px;
        font-size: 1.2em;
        margin: 10px 10px;
        width: 90%;
        border-bottom: 1px solid white;
        /* border-radius: 0px; */
        color: white;
        background-color: #221246;
        min-width: 100px;
        text-align:center;
    }
    

    #signup__button, #login__button {
        min-width: 100px;
        max-width: 150px;
        margin: 10px 10px;
        text-align:center; 
    }

    .header__login {
        align-items: flex-end;
        margin: 10px 10px;
        text-align:left;
    }
    
    .header__login:hover,
    .header__login:active {
        font-size: 1.10em;
        border: 3px solid #221246;
        background-color: white;
        color: #472e85;
    }
    .header__signUp:hover,
    .header__signUp:active {
        font-size: 1.10em;
        border: 3px solid #221246;
        background-color: white;
        color: #472e85;
    }
    
    
}



