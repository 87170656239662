
.profile__container{
    position: relative;
    display: flex;
    flex-wrap: wrap; 
    flex-direction: row;

    width: 70vw;
    text-align: center;
    justify-content: space-evenly;
    padding-top: 5vh;
    flex: 1 11em;

    margin: auto;
    align-items: start;
}

.profile__left {
    width: 100%;
    max-width: 500px;
    min-width: 300px;
    padding: 10px;
    border-color: var(--theme);
    border-radius: 5px;
}

.edit__icon {
    cursor: pointer;
    font-size: 1.5rem;
}

.edit__icon:hover,
.edit__icon:active{
    font-size: 2rem;
}

.profile__right{
    width: 30%;
    min-width: 300px;
    background-color: white;
    border: 1px solid var(--theme);
    border-radius: 5px;
    padding: 10px;
    margin: 1% 1%;
    padding: 2%;
}

.profile__details{
    padding: 5px;
}

.profile__image {
    display: flex;
    margin: 0 auto;
    width: 185px;
    object-fit: contain;
    margin-top: 0px;
    border-radius: 100px;
}


.sign__out {
    padding: 10px 25px;
    font-size: 1.05em;
    margin: 20px;
    border: 2px solid var(--theme);
    border-radius: 50px;
    color: var(--theme);
    background-color: white;
    cursor: pointer;
}

.sign__out:hover,
.sign__out:active {
    font-size: 1.10em;
    border: 3px solid white;
    background-color: #472e85;
    color: white;
}

/* @media (max-width:1160px){
    
} */

.myProfileBook__container{

    display: flex;
    flex-direction: column;
    /* position: relative; */
    background-color: white;
    justify-content:stretch;
    min-width: 260px;
    margin: 1% 1%;
    padding: 2%;
    border: 1px solid var(--theme);
    border-radius: 5px;
    flex: 1 11em;
}

.myProfileBook__book {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.book__right__list > *{
    margin-bottom: 10px;

}

.book__right__list {
    margin-left: 15px;
}

.book__image {
    margin-bottom: 10px;

}

.book__image__list{
    display: flex;
    height: 170px;
    object-fit: contain;
    margin-top: 0px;

}

.right{
    text-align: right;
    /* background-color: aqua; */
}

#book__is__available {
    border-color: gray;
}

.no__book__msg {
    margin: 10px;
}
.gray{
    color: gray
}





