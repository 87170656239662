html{
    --gap: 15rem;
}

.section__container{
    padding: 0 var(--gap);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 10px;

}

.white{
	color: white;
}

.black{
    color: black;
}

.AboutUs{
	/* width: 100%; */
	height: 100%;
	font-size: 80px;
	font-style: normal;
	font-weight: bold;
	line-height: 12px;
	color: white;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: rgda(0,0,0,0.5); 
}

.Chapter__image{
    height: 250px;;
}

.Title{
    margin: 50px 0; 
    margin: 10px; 
    max-width: 40%;
	margin: 0 auto;
	font-size: 2rem;
	font-style: normal;
	font-weight: bold;
	line-height: 20px;
    display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: rgda(0,0,0,0.5); 
}

.bigimage1{
	background-image: url('../img/q4.png');
}

.paragraph{
    margin: 50px 0; 
    max-width: 60%;
	float: left;
	text-align: center;
	font-size: 1.5rem;
	font-style: normal;
	font-weight: normal;
	line-height: 30px;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	background-color: rgda(0,0,0,0.5); 
}


.bg1{
	height: 90vh;
	background-position: center;
	position: relative;
	background-image: url('../img/1q.jpeg');
	background-size: cover;
    
}
.background {
    height: 70vh;
	background-position: center;
	position: relative;
    background-size: cover;
    display: flex;
	align-items: center;
}
.bigimage2{
	background-image: url('../img/q2.png');

}
.bigimage3{
	background-image: url('../img/q3.png');
}
.bigimage4{
	background-image: url('../img/q4.png');
}
.bigimage5{
	background-image: url('../img/q5.jpeg');
}
@media (max-width:1160px){
    html {
        /* --maxWidth: 1920px; */
        --gap: 1rem;
    }
    .Chapter__image{
        height: 150px;
    }
    .AboutUs{
        font-size: 2rem; 
    }
    .background {
        min-height: 30vh;
        background-position: center;
        position: relative;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bigimage4{
        min-height: 90vh;
    }
    .Title{
        margin: 60px 0 0 0; 
        max-width: 100%;
        font-size: 1.8rem; 
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .paragraph{
        margin: 40px 0; 
        max-width: 95%;
        float: left;
        text-align: center;
        font-size: 1.1rem;
        font-style: normal;
        font-weight: normal;
        line-height: 30px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        background-color: rgda(0,0,0,0.5); 
    }

}




