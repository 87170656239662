
#footer__horizontal__line {
    border-color: #fe8d2d;
    background-color:#fe8d2d;
    border-radius: 5px;
    position: relative;
    top: -2px;
    height: 4px;
    margin: auto;
    width: 80%;
}


.main__footer{
    text-align:center;
    position: relative;
    margin: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    /* height: 50hv; */
    background-image: url("../img/bg__footer.png");
    /* background-size: 100% 100%; */
     
}
.margin__top__60{
    margin-top: 60px;
}

.footer__container{
    margin:auto;
    width: 85%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-top:40px;
    padding-bottom:130px;
}

.club__logo{
    float: left;
    width: 95%;
    max-width: 500px;
    flex: 53.33%;
    
}

.footer__image{
    max-width:100%;
    height: 120px;;
}

.Footer__info{
    float: right;
    width: 85%;
    max-width: 550px;
    flex: 13.33%;
  }

.footer__ul{
    list-style: none ;
    color: #221246;
}

.footer__li{
    margin: 10px;
    font-size: 0.95rem;
}

@media(max-width:460px) {
    .footer__container{
        padding-top:15px;
        padding-bottom:70px;
    }

    .footer__image{
        max-width:100%;
        height: 95px;;
    }

    .footer__li{
        margin: 5px;
        font-size: 0.75rem;
    }

}